<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 visitor-management-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="reception-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: routeHelp
        }"
      >
        <template v-slot:left>
          <router-link
            v-if="!selectMode"
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("visitor-management", "visit", "visits") }}
        </div>
        <template v-slot:right>
          <router-link
            :to="{
              name: routeEditVisitorAddVisit,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('visitor-management', 'visit', 'search')
            "
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>

    <section>
      <template
        v-for="(group, index) in [
          L_VISITS_TODAY,
          L_VISITS_FUTURE,
          L_VISITS_PAST
        ]"
        :key="index"
      >
        <visits-group
          v-if="visitsList[group] && visitsList[group].length"
          :group="group"
          :selectedItem="selectedItem"
          :setSelectedItem="setSelectedItem"
          :visits="visitsList[group]"
          :selectMode="selectMode"
        />
      </template>
    </section>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: selectMode }"
              @click="setSelectMode"
            >
              {{ displayLabelName("visitor-management", "visit", "select") }}
            </button>
          </li>
          <li class="action" :style="deleteButtonStyle">
            <button class="action-btn" @click="showDialog">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
          </li>
        </ul>
      </nav>
    </template>

    <screen-modal
      v-if="showDeleteDialog"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteDialog"
      @close="showDeleteDialog = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("visitor-management.visits.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("visitor-management.visits.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";
import {
  L_VISITS_PAST,
  L_VISITS_TODAY,
  L_VISITS_FUTURE
} from "@/services/consts/modules/visitor-management/visits";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "Visits",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    VisitsGroup: defineAsyncComponent(() =>
      import("@/components/visitor-management/Visit/VisitsGroup")
    )
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  created() {
    this.getVisits({
      payload: {
        visitor_id: this.visitorId,
        reception_desk_id: this.receptionDeskId
      },
      groups: [L_VISITS_PAST]
    });
  },
  data() {
    return {
      searchQuery: "",
      datetime_from: DateTime.now().toFormat("dd-MM-yyyy HH:mm"),
      selectMode: false,
      selectedItem: null,
      showDeleteDialog: false,
      L_VISITS_PAST: L_VISITS_PAST,
      L_VISITS_TODAY: L_VISITS_TODAY,
      L_VISITS_FUTURE: L_VISITS_FUTURE,
      helpSlug: "visitor-visit"
    };
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("visit", ["visits"]),
    routeEditVisitorAddVisit() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk-edit-visitor-add-visit";
      }
      return "r_reception-desk-edit-visitor-add-visit";
    },
    routeHelp() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-visitor-edit-visit-help-online";
      }
      return "r_visitor-edit-visit-help-online";
    },
    visitsList() {
      const query = this.searchQuery;
      if (this.visits) {
        if (query && query.length > 1) {
          let visitsFiltered = {};
          Object.keys(this.visits).forEach(group => {
            visitsFiltered[group] = this.visits[group].filter(
              item =>
                `${item.host.first_name} ${item.host.last_name}`
                  .toLowerCase()
                  .includes(query.toLowerCase()) ||
                this.displayDate(item.datetime_from)
                  .toLowerCase()
                  .includes(query.toLowerCase()) ||
                this.displayDate(item.datetime_to)
                  .toLowerCase()
                  .includes(query.toLowerCase())
            );
          });
          return visitsFiltered;
        } else {
          return this.visits;
        }
      }
      return null;
    },
    deleteButtonStyle() {
      const visibility =
        this.selectMode && this.selectedItem !== null ? "visible" : "hidden";

      return {
        visibility
      };
    },
    visitorId() {
      const { visitorId } = this.$route.params;
      return visitorId;
    },
    receptionDeskId() {
      const { receptionDeskId } = this.$route.params;
      return receptionDeskId;
    }
  },
  methods: {
    ...mapActions("visit", ["getVisits", "deleteVisit"]),
    displayDate(date) {
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    setSelectedItem(id) {
      this.selectedItem = id;
    },
    setSelectMode() {
      this.selectedItem = null;
      this.selectMode = !this.selectMode;
    },
    startDelete() {
      const payload = {
        visitId: this.selectedItem,
        receptionDeskId: this.receptionDeskId
      };
      this.deleteVisit(payload).then(() => {
        this.selectedItem = false;
        this.getVisits({
          payload: {
            visitor_id: this.visitorId,
            reception_desk_id: this.receptionDeskId
          }
        }).then(response => {
          let refresh = false;

          response.forEach(visits => {
            if (!visits.length || visits.length < 1) {
              refresh = true;
            }
          });
          if (refresh) {
            this.$store.commit("visitorManagement/setRefreshVisitors", true, {
              root: true
            });

            this.$router.push({
              name: "r_visitor-management-reception-desk",
              params: this.$route.params
            });
          }
        });
      });
    },
    showDialog() {
      this.showDeleteDialog = true;
    },
    closeExportDialog() {
      this.showDeleteDialog = false;
    }
  }
};
</script>
